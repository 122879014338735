import {
  AccountClassType,
  AccountStatus,
  AccountStatusReason,
  AccountType,
  BrokerOfRecord,
  ProgramType,
  SalesStatusReason,
  SalesSubstage,
  VerificationStatus,
} from '@newfront-insurance/account-api';
import type { Account as ApiAccount } from '@newfront-insurance/account-api';
import omit from 'lodash/omit';

import { LEGACY_ADDRESS } from './address';
import { NOW, NOW_DATE } from './dates';
import { ACCOUNT_EMPLOYEE_ROLE_MAPPINGS } from './employees';
import { BUSINESS_IDENTIFIER, API_BUSINESS_IDENTIFIER } from './identities';
import { ACCOUNT_UUID, PRIMARY_CONTACT_UUID, PRIMARY_PRODUCER_EMPLOYEE_UUID, PRIMARY_PRODUCER_UUID } from './uuids';
import type { Account } from '../bff/types';

export const API_ACCOUNT: ApiAccount = {
  accountClassType: AccountClassType.CLIENT,
  programType: ProgramType.PROPERTY_AND_CASUALTY,
  uuid: ACCOUNT_UUID,
  legacyClientId: undefined,
  primaryProducerUuid: PRIMARY_PRODUCER_UUID,
  primaryProducerEmployeeUuid: PRIMARY_PRODUCER_EMPLOYEE_UUID,
  affiliateGroupUuid: undefined,
  affinityGroups: [],
  accountUuid: ACCOUNT_UUID,
  type: AccountType.COMMERCIAL,
  brokerOfRecord: BrokerOfRecord.NEWFRONT,
  name: 'Apple Company Inc',
  mailingAddress: LEGACY_ADDRESS,
  details: {
    dba: 'Testing dba',
    notes: 'Testing Notes',
    naicsData: {
      code: 921130,
      name: 'Public Finance Activities',
    },
    legacyProducerUuid: undefined,
    source: undefined,
  },
  leadStatus: {
    status: SalesSubstage.NEW,
    statusReason: SalesStatusReason.CREATED,
    statusDate: NOW_DATE,
  },
  status: {
    status: AccountStatus.ACTIVE,
    statusReason: AccountStatusReason.CREATED,
    statusDate: NOW_DATE,
  },
  verificationStatus: VerificationStatus.PENDING,
  identities: [API_BUSINESS_IDENTIFIER],
  primaryContactUuid: PRIMARY_CONTACT_UUID,
  clientAccessFeatures: {
    claims: true,
  },
  updatedAt: NOW_DATE,
  createdAt: NOW_DATE,
};

export const ACCOUNT: Account = {
  ...omit(API_ACCOUNT, 'accountUuid', 'identities', 'leadStatus'),
  accountClassType: AccountClassType.CLIENT,
  uuid: API_ACCOUNT.uuid as string,
  businessIdentifier: BUSINESS_IDENTIFIER,
  isNewfrontBrokerage: true,
  createdAt: NOW,
  dba: API_ACCOUNT.details.dba,
  details: {
    ...API_ACCOUNT.details,
    followupDate: API_ACCOUNT.details.followupDate?.toISOString(),
    dba: API_ACCOUNT.details.dba,
  },
  employeeRoleMappings: ACCOUNT_EMPLOYEE_ROLE_MAPPINGS,
  name: API_ACCOUNT.name,
  personalIdentities: [],
  status: {
    ...API_ACCOUNT.status,
    statusDate: NOW,
  },
  updatedAt: NOW,
};
