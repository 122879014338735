import { Children } from 'react';
import * as React from 'react';

import { IconWarning } from '../icons';
import { MiniIconQuestionMark } from '../mini-icons';
import { Text } from '../text';
import { Tooltip } from '../tooltip';

interface Props {
  fieldId: string;
  label?: string;
  hint?: string;
  error?: string | false;
  children: React.ReactNode;
  subText?: string;
  tooltip?: string;
  renderHelper?: React.ReactNode;
  hideEmptyLabel?: boolean;
  style?: React.CSSProperties;
  touched?: boolean;
  horizontal?: boolean;
  compact?: boolean;
}

function shouldShowError(error: Props['error'], touched: Props['touched']): boolean {
  // Show error if the error type is a string and the field is touched
  if (typeof touched !== 'undefined') {
    return typeof error === 'string' && touched;
  }

  // If not, show error if the error type is a string
  if (typeof error === 'string') {
    return true;
  }

  return false;
}

interface AcceptsFieldId extends React.ReactElement<unknown, string | React.JSXElementConstructor<unknown>> {
  fieldId?: string;
}

export function Field(props: Props): JSX.Element {
  const {
    children,
    label,
    hint,
    error,
    fieldId,
    subText,
    tooltip,
    renderHelper,
    hideEmptyLabel = false,
    style,
    touched,
    horizontal,
    compact,
  } = props;

  const canInjectFieldId =
    React.isValidElement(children) &&
    !children.props.fieldId &&
    Children.count(children) === 1 &&
    typeof children.type !== 'string';
  const showError = shouldShowError(error, touched);

  const containerClassName = horizontal ? 'flex flex-row gap-3 items-center' : 'flex flex-col gap-2';
  const labelRenderHelperClassName = horizontal ? 'flex flex-col' : 'flex flex-row justify-between';
  const labelClassName = horizontal ? 'flex flex-col' : 'flex flex-row items-center gap-1';
  const labelTooltipClassName = horizontal ? 'flex flex-row items-center gap-1' : '';

  return (
    <div className={containerClassName} key={label} data-testid={fieldId} style={style}>
      {(label || subText || tooltip || renderHelper || !hideEmptyLabel || (showError && compact)) && (
        <div className={labelRenderHelperClassName}>
          <label htmlFor={fieldId}>
            <div className={labelClassName}>
              <div className={labelTooltipClassName}>
                <Text
                  weight={600}
                  color="dark"
                  style={{ minHeight: compact ? undefined : '21px' }}
                  size={compact ? 'small' : 'regular'}
                >
                  {label}
                </Text>
                {tooltip && horizontal ? (
                  <Tooltip text={tooltip} positionTip="left">
                    <MiniIconQuestionMark />
                  </Tooltip>
                ) : null}
                {showError && error && compact ? (
                  <Tooltip message={error} positionTip={horizontal ? 'left' : 'right'}>
                    <IconWarning size={12} />
                  </Tooltip>
                ) : null}
              </div>
              {subText ? (
                <Text weight={300} color="dark" size={compact ? 'small' : 'regular'}>
                  {subText}
                </Text>
              ) : null}
              {tooltip && !horizontal ? (
                <Tooltip text={tooltip}>
                  <MiniIconQuestionMark />
                </Tooltip>
              ) : null}
            </div>
          </label>
          {renderHelper}
        </div>
      )}
      <div className="flex w-auto flex-col gap-2">
        {canInjectFieldId ? React.cloneElement(children as React.ReactElement<AcceptsFieldId>, { fieldId }) : children}
        {showError && !compact ? (
          <Text
            color="error"
            size="small"
            data-testid={`field:error:${fieldId}`}
            textAlign={horizontal ? 'right' : 'left'}
          >
            {error}
          </Text>
        ) : (
          hint &&
          !compact && (
            <Text size="small" textAlign={horizontal ? 'right' : 'left'}>
              {hint}
            </Text>
          )
        )}
      </div>
    </div>
  );
}
