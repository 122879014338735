/* eslint react/jsx-props-no-spreading: 0 */
import type { CSSProperties } from 'react';
import * as React from 'react';
import styled from 'styled-components';

import type { SpacingValue } from '../../../theme';
import { Padding } from '../../padding';

interface ExpandableBodyProps {
  // eslint-disable-next-line
  getCollapseProps?: any;
  children: React.ReactNode;
  paddingX?: SpacingValue;
  paddingBottom?: SpacingValue;
  defaultOverflow?: CSSProperties['overflow'];
  backgroundColor?: CSSProperties['backgroundColor'];
}

export const ExpandableBody = ({
  getCollapseProps,
  children,
  paddingX = 24,
  paddingBottom = 24,
  defaultOverflow = 'hidden',
  backgroundColor = 'none',
}: ExpandableBodyProps): JSX.Element => (
  <StyledBody backgroundColor={backgroundColor} defaultOverflow={defaultOverflow} {...getCollapseProps()}>
    <Padding bottom={paddingBottom} x={paddingX}>
      {children}
    </Padding>
  </StyledBody>
);

const StyledBody = styled.div<ExpandableBodyProps>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  overflow: ${({ defaultOverflow }) => defaultOverflow};
`;
