/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import type {
  ClassNamesConfig,
  IndicatorsContainerProps,
  InputActionMeta,
  MenuPlacement,
  OptionsOrGroups,
} from 'react-select';
import Select, { components } from 'react-select';
import styled from 'styled-components';

import { IconSelect } from './icons';
import { isGroupedOption } from './lib';
import { fontFamilies, fontSizes, zIndices } from '../../../theme';
import { colors } from '../../../theme/colors';
import { cn } from '../../../v2';
import { IconButton } from '../../icon-button';
import { IconSearch } from '../../icons';
import { Spacing } from '../../spacing';
import { TextLink } from '../../text-link';
import { Tooltip } from '../../tooltip';
import type { GroupedOptions, Option } from '../types';

interface Props {
  defaultValue?: string;
  disabled?: boolean;
  isClearable?: boolean;
  isInvalid?: boolean;
  isLoading?: boolean;
  isMulti?: boolean;
  menuPlacement?: MenuPlacement;
  menuPortalTarget?: HTMLElement | null;
  name?: string;
  customFilter?: (option: Option, rawInput: string) => boolean;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange: (value: string | string[]) => void;
  onInputChange?: (newValue: string, inputActionMeta: InputActionMeta) => void;
  options?: Option[] | GroupedOptions[];
  value?: string | string[] | null;
  placeholder?: string;
  icon?: 'select' | 'search';
  id?: string;
  testId?: string;
  inputId?: string;
  hoverColor?: string;
  maxOptionWidth?: number;
  // Passed to React-select. Forces component to only be a dropdown w/o text input
  isSearchable?: boolean;
  classNames?: ClassNamesConfig<Option, true, GroupedOptions>;
  /** Renders compact variant */
  isCompact?: boolean;
  ariaLabel?: string;
}

interface ControlState {
  isFocused: boolean;
  isSelected: boolean;
  data: Option;
}

/**
 * Takes an array of options that may contain option groups and finds
 * the option that has the value that matches the search value.
 * @param options Array of options or grouped options
 * @param value The option value to search for
 */
function findOptionValue(
  options: OptionsOrGroups<Option, GroupedOptions>,
  isControlled: boolean,
  searchValue?: string | null,
): Option | null | undefined {
  if (!searchValue) return undefined;
  let matched: undefined | null | Option;
  options.forEach((option: Option | GroupedOptions) => {
    if (!isGroupedOption(option)) {
      if (option.value === searchValue) {
        matched = option;
      }
    } else if (!matched) {
      matched = findOptionValue(option.options, isControlled, searchValue);
    }
  });
  return matched || (isControlled ? null : undefined);
}

/**
 * Formatter for react-select. This formats the groups of options within
 * the dropdown menu.
 * @param data
 */
function formatGroupLabel(data: GroupedOptions): JSX.Element {
  const groupStyles: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: data.justifyContent || 'space-between',
    textTransform: 'uppercase',
    fontSize: 10,
    fontWeight: 'bold',
    gap: '8px',
  };

  const groupBadgeStyles: React.CSSProperties = {
    display: 'none',
  };

  return (
    <div style={groupStyles}>
      {data.icon && <div>{data.icon}</div>}
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );
}

/*
  For other React-Select features please see https://react-select.com
  Features not yet implemented:
    - Async: for loading data asynchronously, shows loading indicators
    - Creatable: giving the user the ability to create new options on the fly
    - Fixed Options: works with Multi-select to pre-selected options
*/
export function AdvancedSelectInput(props: Props): JSX.Element {
  const {
    defaultValue = '',
    disabled,
    isClearable,
    isInvalid,
    isLoading,
    isMulti,
    menuPlacement = 'auto',
    menuPortalTarget = document.body,
    name,
    customFilter,
    onBlur,
    onChange,
    onInputChange,
    options = [],
    value,
    placeholder,
    icon = 'select',
    id,
    testId,
    inputId,
    hoverColor,
    isSearchable = true,
    classNames,
    maxOptionWidth,
    isCompact = false,
    ariaLabel,
  } = props;
  const isControlled = 'value' in props;

  const defaultOption = findOptionValue(options, isControlled, defaultValue);
  const optionValue =
    value && Array.isArray(value)
      ? (value.map((val) => findOptionValue(options, isControlled, val)).filter((val) => !!val) as Option[])
      : findOptionValue(options, isControlled, value as string);
  const [isFocused, setFocused] = React.useState(false);

  function handleChange(option?: Option | Option[]): void {
    if (!option) {
      onChange('');
      return;
    }
    if (Array.isArray(option)) {
      onChange(option.map((o) => o.value));
    } else {
      onChange(option.value);
    }
  }

  /**
   * Formatter for react-select. This formats options within the dropdown
   * @param data
   * @param context
   */
  function formatOptionLabel(data: Option, context: { context: 'menu' | 'value' }): JSX.Element {
    const { context: optionContext } = context;

    const labelMarkup = (
      <div
        className="flex flex-row items-center"
        style={{
          marginLeft: optionContext === 'menu' ? `${data.spacingLeft}px` : undefined,
        }}
      >
        {data.icon}
        <Spacing width={8} />
        <div className="flex flex-col" style={data.descriptionFlexboxStyles ?? undefined}>
          <span
            className={cn({
              'overflow-hidden text-ellipsis whitespace-nowrap': maxOptionWidth && optionContext === 'value',
            })}
            style={{
              maxWidth: maxOptionWidth && optionContext === 'value' ? `${maxOptionWidth}px` : undefined,
            }}
          >
            {data.label}
          </span>
          {data.description && optionContext === 'menu' && (
            <span className="block pt-[2px] text-sm">{data.description}</span>
          )}
        </div>
        {/* Note: if you need the icon to be more to the right, set descriptionFlexboxStyles to have "flex-grow": "1" */}
        {data.iconRight}
      </div>
    );

    if (optionContext === 'value') {
      return (
        <Tooltip message={data.label} maxWidth={400} positionTip="top">
          {labelMarkup}
        </Tooltip>
      );
    }

    return labelMarkup;
  }

  /**
   * Custom styles for react-select
   */
  const selectStyles = {
    dropdownIndicator: () => ({
      visibility: 'hidden',
    }),
    loadingIndicator: (styles: React.CSSProperties) => ({
      ...styles,
      paddingRight: 16,
    }),
    clearIndicator: () => ({
      display: 'flex',
      alignItems: 'center',
      marginRight: 16,
      ':hover': {
        color: colors.brand[400],
        cursor: 'pointer',
        background: colors.steel[100],
        borderRadius: 3,
      },
    }),
    container: (styles: React.CSSProperties) => ({
      ...styles,
      fontFamily: fontFamilies.body,
      fontSize: fontSizes.regular.fontSize,
    }),
    valueContainer: (styles: React.CSSProperties) => ({
      ...styles,
      padding: isCompact ? '0 8px' : styles.padding,
    }),
    control: (_styles: React.CSSProperties, state: ControlState) => {
      const focusStyles =
        state.isFocused || state.isSelected
          ? {
              border: `1px solid ${colors.brand[400]}`,
            }
          : {
              border: `1px solid ${colors.steel[200]}`,
            };
      const invalidStyles = isInvalid
        ? {
            borderColor: colors.fire[600],
            boxShadow: '0 0 0 3px rgba(189, 73, 50, 0.25)',
          }
        : {};
      return {
        ...focusStyles,
        ...invalidStyles,
        lineHeight: '21px',
        color: colors.steel[150],
        minHeight: isCompact ? undefined : '40px',
        boxSizing: 'border-box',
        width: '100%',
        borderRadius: '4px',
        background: 'white',
        appearance: 'none',
        display: 'flex',
        alignItems: 'center',
      };
    },
    option: (_provided: unknown, state: ControlState) => {
      const focusedColor = hoverColor ?? colors.brand[400];
      const focused = state.isFocused
        ? {
            background: focusedColor,
            color: 'white',
          }
        : {};
      const optionDisabled = state.data.isDisabled
        ? {
            color: colors.steel[200],
            cursor: 'default',
            ':hover': {
              background: 'none',
            },
          }
        : {};
      return {
        fontSize: fontSizes.regular.fontSize,
        fontFamily: fontFamilies.body,
        padding: '8px 12px',
        cursor: 'pointer',
        color: colors.steel[500],
        background: state.isSelected ? colors.steel[100] : 'white',
        ':hover': {
          background: focusedColor,
          color: 'white',
        },
        ...focused,
        ...optionDisabled,
        ...state.data.containerStyles,
      };
    },
    placeholder: (styles: React.CSSProperties) => ({
      ...styles,
      color: colors.steel[300],
      lineHeight: '21px',
    }),
    menu: (styles: React.CSSProperties) => ({
      ...styles,
      zIndex: zIndices.select,
    }),
    menuPortal: (styles: React.CSSProperties) => ({
      ...styles,
      zIndex: zIndices.select,
    }),
    multiValue: () => ({
      display: 'flex',
      alignItems: 'center',
      padding: 0,
      fontSize: 12,
      background: colors.brand[100],
      boxShadow: `0 0 0 1px inset ${colors.glitter[100]}`,
      borderRadius: 2,
      height: 24,
      marginRight: 4,
      marginTop: 2,
      marginBottom: 2,
    }),
    multiValueLabel: () => ({
      color: colors.brand[400],
      padding: '0 0 0 8px',
    }),
    multiValueRemove: () => ({
      color: colors.brand[400],
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 24,
      width: 24,
      boxShadow: `1px 0 inset ${colors.glitter[100]}`,
      borderTopRightRadius: 2,
      borderBottomRightRadius: 2,
      marginLeft: 8,
      cursor: 'pointer',
      ':hover': {
        background: colors.brand[400],
        color: 'white',
      },
    }),
    noOptionsMessage: (styles: React.CSSProperties) => ({
      ...styles,
      fontFamily: fontFamilies.body,
      fontSize: fontSizes.regular.fontSize,
    }),
    loadingMessage: (styles: React.CSSProperties) => ({
      ...styles,
      fontFamily: fontFamilies.body,
      fontSize: fontSizes.regular.fontSize,
    }),
    input: (styles: React.CSSProperties) => ({
      ...styles,
      height: '24px',
      marginTop: 2,
      marginBottom: 2,
    }),
  };

  return (
    <StyledDiv onFocus={() => setFocused(true)} onBlur={() => setFocused(false)}>
      <Select
        classNames={classNames}
        inputId={inputId}
        data-testid={testId}
        defaultValue={defaultOption}
        classNamePrefix="react-select"
        filterOption={customFilter}
        formatGroupLabel={formatGroupLabel}
        formatOptionLabel={formatOptionLabel}
        isClearable={isClearable}
        isSearchable={isSearchable}
        isDisabled={disabled}
        isLoading={isLoading}
        isMulti={isMulti || undefined}
        menuPortalTarget={menuPortalTarget}
        menuPlacement={menuPlacement}
        captureMenuScroll
        name={`advanced-select-${name}`}
        options={options}
        styles={selectStyles as any}
        onBlur={onBlur}
        onChange={handleChange as any}
        onInputChange={onInputChange}
        placeholder={placeholder}
        value={optionValue ?? null}
        aria-label={ariaLabel}
        components={{
          // TODO - consider moving these out to top-level
          // https://react-select.com/components#defining-components
          ClearIndicator: ({ clearValue }) => (
            <StyledIconButtonWrapper
              onMouseOver={() => {
                if (!isFocused) setFocused(true);
              }}
            >
              <IconButton type="close" onClick={clearValue} />
            </StyledIconButtonWrapper>
          ),
          IndicatorsContainer,
          DropdownIndicator: () => (
            <StyledIcon isFocused={isFocused}>
              {icon === 'select' ? <IconSelect testId="asi-icon-select" /> : <IconSearch testId="asi-icon-search" />}
            </StyledIcon>
          ),
        }}
        closeMenuOnSelect={!isMulti}
      />
      <input type="hidden" name={name} id={id} value={value || ''} />
    </StyledDiv>
  );
}

function IndicatorsContainer(props: IndicatorsContainerProps<Option, true, GroupedOptions>) {
  const { hasValue, selectProps, isMulti, options, setValue } = props;

  function handleSelectAll(): void {
    const optionsToSelect = options
      .flatMap((option) => {
        if (isGroupedOption(option)) {
          return option.options.filter((o) => !o.isDisabled);
        }

        return option.isDisabled ? null : option;
      })
      .filter(Boolean);

    setValue(optionsToSelect as Option[], 'select-option');
  }

  return (
    <div className="flex flex-row items-center gap-2">
      {isMulti && !hasValue && selectProps.menuIsOpen && (
        <div
          aria-hidden="true"
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <TextLink
            underline={false}
            underlineOnHover
            onClick={() => {
              handleSelectAll();
            }}
          >
            Select all
          </TextLink>
        </div>
      )}
      <components.IndicatorsContainer {...props} />
    </div>
  );
}

const StyledIconButtonWrapper = styled.div`
  margin-right: 10px;
`;

const StyledDiv = styled.div`
  position: relative;

  :global(.react-select__control) {
    position: relative;
    z-index: 1;
  }
  :global(.react-select__menu) {
    position: absolute;
    z-index: 2;
  }
  :global(.react-select__single-value) {
    max-width: calc(100% - 80px);
  }
`;

const StyledIcon = styled.div<{ isFocused: boolean }>`
  color: ${({ isFocused }) => (isFocused ? colors.brand[400] : colors.steel[200])};
  padding-left: 10px;
  padding-right: 10px;
  pointer-events: none;
  z-index: 1;
`;
