/* eslint-disable max-len */
import * as React from 'react';
import ReactImageFallback from 'react-image-fallback';
import styled from 'styled-components';

interface EmployeeThumbnailProps {
  imageUrl?: string;
  sizeInPixels: number;
  variant?: 'round' | 'squircle';
}

/**
 * React fallback image will accept an SVG as a fallback, however it wont naturally apply the image styling,
 * hence why we need a StyledSVG component
 * https://www.npmjs.com/package/react-image-fallback#fallbackimage
= */
const MissingProfile = ({ variant, size }: { variant: string; size: number }): JSX.Element => (
  <StyledSVG variant={variant} width={size} height={size} viewBox="89.651 35.942 33.197 17.278" fill="none">
    <rect width="300" height="300" fill="url(#pattern0)" />
    <path
      d="M 116.067 39.813 C 114.284 39.813 112.718 40.746 111.82 42.155 L 113.174 43.99 C 113.174 43.99 113.177 43.981 113.178 43.976 C 113.568 42.741 114.714 41.847 116.067 41.847 C 117.741 41.847 119.098 43.216 119.098 44.905 C 119.098 46.593 117.741 47.962 116.067 47.962 C 115.017 47.962 114.091 47.423 113.547 46.605 L 109.962 41.789 C 109.039 40.587 107.595 39.813 105.972 39.813 C 104.184 39.813 102.615 40.751 101.718 42.165 L 103.079 43.988 C 103.079 43.988 103.082 43.98 103.083 43.976 C 103.473 42.741 104.619 41.847 105.972 41.847 C 107.018 41.847 107.94 42.382 108.485 43.195 C 108.485 43.195 111.057 46.646 111.969 47.878 C 112.882 49.109 114.379 49.997 116.067 49.997 C 118.855 49.997 121.114 47.717 121.114 44.905 C 121.114 42.093 118.855 39.813 116.067 39.813"
      id="Fill-10"
      fill="#db0e63"
    />
    <path
      d="M 106.298 48.12 C 105.277 48.12 104.375 47.594 103.825 46.79 L 100.393 42.044 C 99.486 40.641 97.945 39.717 96.197 39.717 C 93.408 39.717 91.147 42.068 91.147 44.967 C 91.147 47.866 93.408 50.217 96.197 50.217 C 97.985 50.217 99.555 49.251 100.452 47.794 L 99.09 45.916 C 99.09 45.916 99.084 45.936 99.081 45.946 C 98.685 47.207 97.544 48.12 96.197 48.12 C 94.523 48.12 93.164 46.708 93.164 44.967 C 93.164 43.226 94.523 41.814 96.197 41.814 C 97.164 41.814 98.025 42.285 98.58 43.018 C 98.58 43.018 101.129 46.53 102.099 47.883 C 103.117 49.301 104.547 50.217 106.298 50.217 C 108.083 50.217 109.65 49.254 110.548 47.802 L 109.193 45.909 C 109.193 45.909 109.186 45.933 109.182 45.945 C 108.786 47.207 107.645 48.12 106.298 48.12"
      id="Fill-11"
      fill="#db0e63"
    />
  </StyledSVG>
);

// https://medium.com/@zubryjs/squircles-bringing-ios-7s-solution-to-rounded-rectangles-to-css-9fc35779aa65
export function squircleClipPath(radius: number): string {
  return new Array(360)
    .fill(0)
    .map((_x, i) => i)
    .map((deg) => (deg * Math.PI) / 180)
    .map((theta: number) => ({
      x: Math.abs(Math.cos(theta)) ** (2 / radius) * 50 * Math.sign(Math.cos(theta)) + 50,
      y: Math.abs(Math.sin(theta)) ** (2 / radius) * 50 * Math.sign(Math.sin(theta)) + 50,
    }))
    .map(({ x, y }) => ({
      x: Math.round(x * 10) / 10,
      y: Math.round(y * 10) / 10,
    })) // Round to the ones place
    .map(({ x, y }) => `${x}% ${y}%`)
    .join(', ');
}

export const EmployeeThumbnail: React.FunctionComponent<EmployeeThumbnailProps> = ({
  imageUrl,
  sizeInPixels,
  variant = 'round',
}): React.ReactElement => (
  <StyledImage
    className="photo"
    alt="employee photo"
    fallbackImage={<MissingProfile variant={variant} size={sizeInPixels} />}
    height={sizeInPixels}
    width={sizeInPixels}
    src={imageUrl}
    variant={variant}
  />
);

interface ImageProps {
  variant: string;
}

const sharedVariantStyles = (variant: string): string => `
  ${variant === 'squircle' ? `clip-path: polygon(${squircleClipPath(6)});` : 'border-radius: 50%;'};
`;

const StyledImage = styled(ReactImageFallback)<ImageProps>`
  ${({ variant }) => sharedVariantStyles(variant)}
`;

const StyledSVG = styled.svg<ImageProps>`
  ${({ variant }) => sharedVariantStyles(variant)}
`;
