import { CoverageDslProvider } from '@newfront-insurance/admin-ui/coverage';
import { FallbackSuspense } from '@newfront-insurance/admin-ui/layout';
import { useFeatureFlag } from '@newfront-insurance/app-providers';
import type { QueryObserverOptions } from '@tanstack/react-query';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import type { ReactNode } from 'react';

import { AccountUIProvider } from './account-ui-api';
import { AdminUIProvider } from './admin-ui';
import { AccountsCoverageApiProvider } from './coverage-api';
import { IncompleteUserErrorProvider } from './incomplete-user-error-provider';
import { AccountsNewfrontApiProvider } from './newfront-api';
import { PostLoginRedirectProvider } from './post-login-redirect';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      useErrorBoundary: (_error, query) => {
        return !query.state.data && (query.options as QueryObserverOptions).suspense === true;
      },
    },
  },
});

interface Props {
  children: ReactNode;
}

export function ReactQueryProvider({ children }: Props): JSX.Element {
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
}
/**
 * Compose all providers together into a single component
 */
export function Providers({ children }: Props): JSX.Element {
  const isShowingDarkSidebar = useFeatureFlag('dark-navigator-sidebar', false);

  return (
    <FallbackSuspense>
      <IncompleteUserErrorProvider>
        <AccountUIProvider>
          <AdminUIProvider isShowingDarkSidebar={isShowingDarkSidebar}>
            <PostLoginRedirectProvider>
              <AccountsNewfrontApiProvider>
                <AccountsCoverageApiProvider>
                  <CoverageDslProvider>{children}</CoverageDslProvider>
                </AccountsCoverageApiProvider>
              </AccountsNewfrontApiProvider>
            </PostLoginRedirectProvider>
          </AdminUIProvider>
        </AccountUIProvider>
      </IncompleteUserErrorProvider>
    </FallbackSuspense>
  );
}
