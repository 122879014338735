import { CircleSpinnerInline, colors } from '@newfront-insurance/core-ui';
import { LineOfCoverageStageFilter, type LineOfCoverageSelectInputMetadata } from '@newfront-insurance/coverage-api';
import type { SchemaInputControlCustomInputProps, CustomValue } from '@newfront-insurance/dsl-schema-ui';
import { Application } from '@newfront-insurance/dsl-schema-ui';

import { LineOfCoverageCard } from '../../../components/line-of-coverage-card';
import { LineOfCoverageSelector } from '../../../features/line-of-coverage-selector';
import { useLinesOfCoverage } from '../../../queries/use-lines-of-coverage';

export type LineOfCoverageSelectInputValue = string[] | undefined;

export function LineOfCoverageSelectDslInput({
  onChange,
  value,
  accountUuid,
  app,
  disabled,
  isCompact,
}: SchemaInputControlCustomInputProps<
  LineOfCoverageSelectInputValue,
  LineOfCoverageSelectInputMetadata
>): JSX.Element | null {
  return (
    <LineOfCoverageSelector
      onChange={onChange}
      accountUuid={accountUuid!}
      initLineOfCoverageUuids={value}
      stage={app === Application.QUOTING ? LineOfCoverageStageFilter.ALL : LineOfCoverageStageFilter.BOUNDED}
      disabled={disabled}
      isCompact={isCompact}
    />
  );
}

export const LineOfCoverageSelectDisplay: CustomValue<
  LineOfCoverageSelectInputValue,
  LineOfCoverageSelectInputMetadata
  // eslint-disable-next-line react/function-component-definition
> = ({ value, accountUuid }) => {
  const { data: linesOfCoverages, isInitialLoading: isLoading } = useLinesOfCoverage({
    accountUuid: accountUuid!,
    lineOfCoverageUuids: value,
    stage: LineOfCoverageStageFilter.ALL,
  });

  if (!value || value.length === 0) {
    return <div className="my-1">-</div>;
  }

  return (
    <div className="my-1 flex min-w-10 flex-col">
      {isLoading && <CircleSpinnerInline label="Loading..." />}
      {linesOfCoverages && (
        <div className="flex flex-col gap-2">
          {linesOfCoverages.map((loc) => (
            <LineOfCoverageCard
              key={loc.uuid}
              lineOfCoverage={loc}
              isExpandable
              defaultExpanded={false}
              cardProps={{
                hideBorder: true,
                backgroundColor: colors.steel[50],
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
};
