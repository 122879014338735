/* eslint-disable react/function-component-definition */
import { Button, IconBug, IconNetworkLost, IconNotAllowed, IconNotFound } from '@newfront-insurance/core-ui';
import * as React from 'react';

import type { ErrorBoundaryMessages } from '../../error-boundary/types';

const ASK_BUGS_HREF = 'https://app.slack.com/client/TVBSAQNLS/CPM6JUMCN';
const ASK_PRODUCT_HREF = 'https://app.slack.com/client/TVBSAQNLS/C02EE2ER953';

const AskProductButton: React.FC = () => (
  <Button size="secondary" href={ASK_PRODUCT_HREF} target="_blank" style={{ textAlign: 'center' }}>
    Ask in Slack...
  </Button>
);

const AskBugsButton: React.FC = () => (
  <Button size="secondary" href={ASK_BUGS_HREF} target="_blank" style={{ textAlign: 'center' }}>
    Ask in Slack...
  </Button>
);

const ReloadButton: React.FC = () => (
  <Button size="secondary" onClick={() => window.location.reload()}>
    Try again
  </Button>
);

export const employeeErrorMessages: ErrorBoundaryMessages = {
  genericError: (_error) => {
    return {
      title: 'There was a problem loading this page',
      icon: <IconBug />,
      action: <AskBugsButton />,
      subtitle:
        'Try reloading the page to fix the issue. If you’re still experiencing this issue you can ask in #ask-bugs.',
    };
  },
  notFoundError: (_error) => ({
    title: 'The page you’re looking for doesn’t exist',
    icon: <IconNotFound />,
    action: <AskBugsButton />,
    subtitle: 'Check the URL. If you think this is a mistake please contact #ask-bugs.',
  }),
  permissionError: (_error) => ({
    title: 'You don’t have access to this page',
    icon: <IconNotAllowed />,
    action: <AskProductButton />,
    subtitle: 'If you think this is a mistake you can ask the team in #ask-product.',
  }),
  networkError: (_error) => ({
    title: 'There was a network connection issue that prevented this page from loading',
    icon: <IconNetworkLost />,
    action: <ReloadButton />,
    subtitle: 'Please check your internet connection and try again.',
  }),
  timeoutError: (_error) => ({
    title: 'The page took too long to load',
    icon: <IconNetworkLost />,
    action: <ReloadButton />,
    subtitle: 'Please check your internet connection and try again. If the issue persists please contact #ask-bugs.',
  }),
  notLoggedInError: (_error) => ({
    title: 'You need to be logged in to view this page',
    icon: <IconNotAllowed />,
    subtitle: 'If you think this is a mistake you can ask the team in #ask-product.',
  }),
};
