import styled from 'styled-components';

import { generateTestId } from '../../lib/test-helpers';
import { colors } from '../../theme/colors';
import { cn } from '../../v2';
import { IconWarning, IconCheckActive, IconCircleInformation, IconCross } from '../icons';
import type { TextSizes } from '../text';
import { Text } from '../text';

export type MessageBoxVariant = 'alert' | 'success' | 'neutral' | 'warning' | 'disabled';

interface Props {
  variant: MessageBoxVariant;
  message: string | JSX.Element;
  textSize?: TextSizes;
  action?: {
    text: string;
    onClick: (e?: unknown) => unknown;
  };
  customAction?: JSX.Element;
  prewrap?: boolean;
  borderless?: boolean;
  hasBorderRadius?: boolean;
  testId?: string;
  alignIcon?: 'items-top' | 'items-center' | 'items-end';
}

export const variantColors: { [K in MessageBoxVariant]: { background: string; border: string; color: string } } = {
  alert: { background: '#FFF6F8', border: colors.fire[500], color: colors.fire[500] },
  success: { background: '#F1FBF7', border: colors.grass[400], color: '#0E5464' },
  neutral: { background: colors.brand[100], border: colors.brand[400], color: colors.brand[400] },
  warning: { background: colors.fire[100], border: colors.peach[500], color: '#B37855' },
  disabled: { background: colors.steel[100], border: colors.steel[400], color: colors.steel[400] },
};

const VariantIcon = ({ variant }: { variant: MessageBoxVariant }): JSX.Element | null => {
  switch (variant) {
    case 'alert':
      return <IconWarning color={colors.fire[500]} />;
    case 'success':
      return <IconCheckActive size={16} color={colors.grass[500]} />;
    case 'neutral':
      return <IconCircleInformation color={colors.brand[300]} size={16} />;
    case 'warning':
      return <IconWarning color={colors.peach[500]} />;
    case 'disabled':
      return <IconCross />;
    default:
      return null;
  }
};

export function MessageBox({
  variant = 'success',
  message,
  textSize,
  action,
  customAction,
  prewrap,
  borderless = false,
  hasBorderRadius = true,
  alignIcon = 'items-center',
  testId: propTestId,
}: Props): JSX.Element {
  const testId = propTestId ?? generateTestId(variant, 'message-box');

  return (
    <StyledMessageBox variant={variant} data-testid={testId} borderless={borderless} hasBorderRadius={hasBorderRadius}>
      <div className="p-4">
        <div className="flex items-center justify-between">
          <div>
            <div className="flex flex-row">
              <div className={`flex ${alignIcon}`}>
                <div
                  className={cn('pr-2', {
                    'pt-1': alignIcon === 'items-top',
                    'pb-1': alignIcon === 'items-end',
                  })}
                >
                  <VariantIcon variant={variant} />
                </div>
              </div>
              <div>
                <Text
                  color={variantColors[variant].color}
                  prewrap={prewrap}
                  data-testid={generateTestId(testId, 'text')}
                  size={textSize || 'regular'}
                >
                  {message}
                </Text>
              </div>
            </div>
          </div>

          {action && (
            <div>
              <div className="pr-4">
                <Text color={variantColors[variant].color}>
                  <button
                    className="m-0 cursor-pointer border-none bg-none p-[0.125rem] focus:outline focus:outline-fire-400 active:outline active:outline-fire-400"
                    type="button"
                    onClick={action.onClick}
                    data-testid={generateTestId(testId, 'action')}
                  >
                    <Text weight={600} color={variantColors[variant].color}>
                      {action.text}
                    </Text>
                  </button>
                </Text>
              </div>
            </div>
          )}
          {customAction && (
            <div>
              <div className="pr-4">{customAction}</div>
            </div>
          )}
        </div>
      </div>
    </StyledMessageBox>
  );
}

export const StyledMessageBox = styled.div(
  ({
    variant,
    borderless,
    hasBorderRadius,
  }: {
    variant: MessageBoxVariant;
    borderless: boolean;
    hasBorderRadius: boolean;
  }) => `
    background-color: ${variantColors[variant].background};
    border: ${borderless ? 'none' : `1px solid ${variantColors[variant].border}`};
    border-radius: ${hasBorderRadius ? '3px' : '0'};
    color: ${variantColors[variant].color};
  `,
);
