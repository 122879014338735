import classnames from 'classnames';
import * as React from 'react';
import { forwardRef } from 'react';
import styled from 'styled-components';

import { fontSizes } from '../../theme';
import { colors } from '../../theme/colors';

type TextSizes = 'small' | 'regular' | 'large' | 'extraLarge';

export interface Props {
  children: React.ReactNode;
  nowrap?: boolean;
  truncate?: boolean;
  download?: unknown;
  href?: string;
  rel?: string;
  target?: string;
  type?: string;
  referrerPolicy?: string;
  style?: React.CSSProperties;
  size?: TextSizes;
  disabled?: boolean;
  disabledColor?: string;
  variant?: 'primary' | 'secondary' | 'customColor';
  weight?: 300 | 400 | 500 | 600 | 700;
  underline?: boolean;
  colorOnHover?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => unknown;
  tabIndex?: number;
  testId?: string;
  underlineOnHover?: boolean;
  color?: string;
  disablePosition?: boolean;
}

function ExternalIcon(props: { className?: string }): JSX.Element {
  const { className } = props;
  return (
    <svg className={className} width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path d="M10.5 1.5L1.5 10.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.5 1.5H10.5V7.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export const TextLink = forwardRef<HTMLAnchorElement, Props>((props, ref): JSX.Element => {
  const {
    children,
    disabled,
    disabledColor = colors.steel[300],
    download,
    href,
    rel,
    target,
    type,
    referrerPolicy,
    style,
    size = 'regular',
    onClick,
    tabIndex,
    testId,
    variant = 'primary',
    weight = 400,
    colorOnHover,
    underline = true,
    underlineOnHover = true,
    nowrap = false,
    truncate = false,
    color = colors.brand[400],
    disablePosition,
  } = props;
  const isExternal = target === '_blank';

  const className = classnames({
    disabled,
    isExternal,
    underlineOnHover,
    extraLarge: size === 'extraLarge',
    large: size === 'large',
    regular: size === 'regular',
    small: size === 'small',
  });

  return (
    <StyledTextLink
      className={className}
      download={download}
      href={href}
      rel={rel}
      target={target}
      type={type}
      referrerPolicy={referrerPolicy as React.HTMLAttributeReferrerPolicy}
      style={style}
      size={size}
      data-variant={variant}
      weight={weight}
      colorOnHover={colorOnHover}
      disabledColor={disabledColor}
      underline={underline}
      onClick={onClick}
      tabIndex={tabIndex}
      nowrap={nowrap}
      truncate={truncate}
      data-testid={testId}
      ref={ref}
      color={color}
      disablePosition={disablePosition}
    >
      {children}
      {isExternal && nowrap === true && <StyledExternalIcon />}
    </StyledTextLink>
  );
});

TextLink.displayName = 'TextLink';

const StyledTextLink = styled.a<Props>`
  font-family: 'Open Sans', sans-serif;
  text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
  font-size: 14px;
  line-height: 21px;
  word-break: break-word;
  color: ${({ color }) => color || colors.brand[400]};
  ${({ disablePosition }) => !disablePosition && 'position: relative;'}
  font-weight: ${({ weight }) => weight};

  ${({ nowrap }) => nowrap && `white-space: nowrap;`}

  &.regular {
    font-size: ${fontSizes.regular.fontSize};
    line-height: ${fontSizes.regular.lineHeight};
  }
  &.small {
    font-size: ${fontSizes.small.fontSize};
    line-height: ${fontSizes.small.lineHeight};
  }
  &.large {
    font-size: ${fontSizes.large.fontSize};
    line-height: ${fontSizes.large.lineHeight};
  }
  &.extraLarge {
    font-size: ${fontSizes.extraLarge.fontSize};
    line-height: ${fontSizes.extraLarge.lineHeight};
  }

  ${({ truncate }) =>
    truncate &&
    `
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    `}

  &[data-variant='primary'] {
    color: ${colors.brand[400]};
  }

  &[data-variant='secondary'] {
    color: #262d46;
  }

  &[target='_blank'] {
    padding-right: 16px;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
    transition: color 0.3s ease;

    ${({ colorOnHover }) => `
      color: ${colorOnHover};
    `};
  }

  &.underlineOnHover:hover {
    text-decoration: underline;
  }

  &.disabled {
    pointer-events: none;
    ${({ disabledColor }) => `
    color: ${disabledColor};
    `};
  }

  a,
  span {
    color: inherit !important;
  }
`;

const StyledExternalIcon = styled(ExternalIcon)`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
`;
