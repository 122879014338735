import { AnalyticsProvider as BaseAnalyticsProvider } from '@newfront-insurance/data-layer-client';
import type { AuthProviderContext, AuthProviderProps } from '@newfront-insurance/next-auth';
import type { Provider } from '@newfront-insurance/react-provision';
import type { ConfigType } from '@newfront-insurance/shared-public-config';
import { useMemo } from 'react';
import * as React from 'react';

import { useIsCsa } from '../hooks/use-is-csa';
import { useIsEngineer } from '../hooks/use-is-engineer';
import { useIsGrowthCaller } from '../hooks/use-is-growth-caller';
import { useIsProducer } from '../hooks/use-is-producer';
import { useLoggedInUser } from '../hooks/use-logged-in-user';
import { useIsAccountManager } from '../hooks/useIsAccountManager';

interface Props {
  children: React.ReactNode;
  authProvider: Provider<AuthProviderContext, AuthProviderProps>;
  config: ConfigType;
  analyticsAppName: string;
}

export function AnalyticsProvider({ children, authProvider, config, analyticsAppName }: Props): JSX.Element {
  const user = useLoggedInUser(authProvider);
  const { SEGMENT } = config;

  const isCsa = useIsCsa(authProvider);
  const isProducer = useIsProducer(authProvider);
  const isGrowthCaller = useIsGrowthCaller(authProvider);
  const isEngineer = useIsEngineer(authProvider);
  const isAccountManager = useIsAccountManager(authProvider);

  const userDetails = useMemo(() => {
    if (!user) return undefined;
    return {
      userId: user.uuid,
      traits: {
        email: user.email,
        isAccountManager: !isEngineer && isAccountManager,
        isCsa: !isEngineer && isCsa,
        isGrowthCaller: !isEngineer && isGrowthCaller,
        isProducer: !isEngineer && isProducer,
        isTechTeam: isEngineer,
      },
    };
  }, [isAccountManager, isCsa, isEngineer, isGrowthCaller, isProducer, user]);

  return (
    <BaseAnalyticsProvider
      apiKey={SEGMENT.SOURCES.ADMIN_DASH}
      user={userDetails}
      authProvider={authProvider}
      appName={analyticsAppName}
      heartbeatsEnabled
    >
      {children}
    </BaseAnalyticsProvider>
  );
}
