import { useRef, useState } from 'react';
import * as React from 'react';
import styled from 'styled-components';

import { Portal } from '../../hooks/portal';
import { fontSizes, zIndices } from '../../theme';
import { colors } from '../../theme/colors';
import { Box } from '../box';
import { Flexbox } from '../flexbox';
import { IconCircleInformation } from '../icons';
import { Padding } from '../padding';
import type { AnimationDirection } from '../popover-animation';
import { PopoverAnimation } from '../popover-animation';
import type { PopoverAnchor } from '../tether';
import { Tether } from '../tether';
import { Text } from '../text';

type Position = 'left' | 'right' | 'top' | 'bottom';
type TooltipVariant = 'info';

export interface Props {
  children: React.ReactNode;

  /**
   * This will render text or an element inside of a tooltip component.
   * Text can be a falsy value and in that case the children will be returned
   * @deprecated Use customElement or message instead
   */
  text?: string | React.ReactNode | null | undefined;

  /**
   * Render a custom React node. This lets you render anything you want instead
   * of the basic tooltip. You can import the BasicTooltip to set it up yourself
   * and modify it, or you can use a completely custom element.
   */
  customElement?: React.ReactNode;

  customContainer?: React.ComponentType;

  /**
   * Render text within a tooltip component.
   */
  message?: string;

  followMouse?: boolean;
  testId?: string;
  tipTestId?: string;
  positionTip?: Position;
  // eslint-disable-next-line react/no-unused-prop-types
  offset?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  command?: string;
  style?: React.CSSProperties;
  // eslint-disable-next-line react/no-unused-prop-types
  variant?: TooltipVariant;
  // eslint-disable-next-line react/no-unused-prop-types
  maxWidth?: number;
}

interface TetherOptions {
  yOffset: number;
  xOffset: number;
  animationDirection: AnimationDirection;

  // What the tooltip is attached to
  targetAnchor: PopoverAnchor;

  // The tooltip anchor point
  anchor: PopoverAnchor;
}

/**
 * Map the Position to a set a Tether values
 */
const POSITION_MAPPING: Record<Position, TetherOptions> = {
  bottom: {
    targetAnchor: 'bottom',
    anchor: 'top',
    yOffset: 10,
    xOffset: 0,
    animationDirection: 'down',
  },
  top: {
    targetAnchor: 'top',
    anchor: 'bottom',
    yOffset: -10,
    xOffset: 0,
    animationDirection: 'up',
  },
  right: {
    targetAnchor: 'right',
    anchor: 'left',
    yOffset: 0,
    xOffset: 10,
    animationDirection: 'right',
  },
  left: {
    targetAnchor: 'left',
    anchor: 'right',
    yOffset: 0,
    xOffset: -10,
    animationDirection: 'left',
  },
};

export function Tooltip(props: Props): JSX.Element {
  const {
    children,
    text,
    customElement,
    customContainer,
    message,
    followMouse = false,
    testId,
    tipTestId,
    positionTip = 'right',
    style,
  } = props;
  const targetRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);
  const [isOpen, setOpen] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });

  if (!text && !message && !customElement) {
    return <>{children}</>;
  }

  const { anchor, targetAnchor, xOffset, yOffset, animationDirection } = POSITION_MAPPING[positionTip];

  const ChildrenElement = customContainer || StyledChildren;

  return (
    <>
      <ChildrenElement
        data-testid={testId}
        role="button"
        tabIndex={0}
        onFocus={() => {
          setOpen(true);
        }}
        onMouseOver={() => {
          setOpen(true);
        }}
        onMouseOut={() => {
          setOpen(false);
        }}
        onMouseMove={(e) => {
          setTooltipPosition({ x: e.clientX, y: e.clientY });
        }}
        onBlur={() => setOpen(false)}
        style={style}
        ref={!followMouse ? targetRef : undefined}
      >
        {children}
      </ChildrenElement>
      {followMouse && (
        <div
          style={{ top: tooltipPosition.y + yOffset, left: tooltipPosition.x + xOffset, position: 'fixed' }}
          ref={targetRef}
        >
          <></>
        </div>
      )}
      <Portal name="tooltip">
        <Tether
          targetRef={targetRef}
          targetAnchor={targetAnchor}
          anchor={anchor}
          yOffset={yOffset}
          xOffset={xOffset}
          zIndex={zIndices.tooltip}
        >
          <PopoverAnimation isOpen={isOpen} direction={animationDirection}>
            <div ref={popoverRef} data-testid={tipTestId}>
              {getContent(props)}
            </div>
          </PopoverAnimation>
        </Tether>
      </Portal>
    </>
  );
}

/**
 * This is a helper function to get the content for the tooltip. This exists so that
 * it's more obvious how we're supporting the old `text` prop. We've split this into
 * two props, `message` and `customElement`.
 * @param props
 * @returns
 */
function getContent(props: Props): React.ReactNode {
  const { text, command, message, customElement, variant, maxWidth } = props;

  /**
   * This is the old way we would render element and text.
   */
  if (text) {
    return (
      <BasicTooltip command={command} variant={variant} maxWidth={maxWidth}>
        {text}
      </BasicTooltip>
    );
  }

  /**
   * This does the same thing as `text` but it only supports strings.
   */
  if (message) {
    return (
      <BasicTooltip command={command} variant={variant} maxWidth={maxWidth}>
        {message}
      </BasicTooltip>
    );
  }

  return customElement;
}

interface BasictooltipProps {
  children: React.ReactNode;
  command?: string;
  maxWidth?: number;
  variant?: TooltipVariant;
}

export function BasicTooltip(props: BasictooltipProps): JSX.Element {
  const { children, command, maxWidth = 500, variant } = props;

  return (
    <Box
      borderRadius={6}
      backgroundColor={colors.steel[500]}
      style={{ pointerEvents: 'none', maxWidth }}
      boxShadow="overlay"
    >
      <Padding size={8}>
        <Flexbox alignItems="center" gap={8}>
          {variant ? (
            <Box style={{ flexShrink: 0 }}>
              {variant === 'info' ? <IconCircleInformation size={16} color="white" /> : null}
            </Box>
          ) : null}
          <Text color="white">{children}</Text>
          {command ? <Command>{command}</Command> : null}
        </Flexbox>
      </Padding>
    </Box>
  );
}

const StyledChildren = styled.span`
  cursor: pointer;
  position: relative;

  &:focus {
    outline: 1px dashed ${colors.steel[200]};
  }
`;

export const Command = styled.div`
  display: inline-block;
  font-family: monospace;
  background: #546a83;
  color: #fff;
  padding: 3px 5px;
  border-radius: 2px;
  line-height: 1;
  font-size: ${fontSizes.small.fontSize};
`;
