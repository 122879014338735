import type { AuthProviderContext } from '@newfront-insurance/next-auth';
import type { Provider } from '@newfront-insurance/react-provision';
import { useProvider } from '@newfront-insurance/react-provision';
import type { ConfigType } from '@newfront-insurance/shared-public-config';
import { useQuery } from '@tanstack/react-query';
import { asyncWithLDProvider, useLDClient } from 'launchdarkly-react-client-sdk';

interface Props {
  children: React.ReactNode;
  config: ConfigType;
  authProvider: Provider<AuthProviderContext>;
}

/**
 * Initialises LaunchDarkly and automatically updates the current LaunchDarkly user whenever the auth state changes. We don't
 * set the user here and we just assume they're anonymous. We'll use the provider below to keep the LaunchDarkly user and the Newfront
 * user in sync.
 */
export function FeatureFlagProvider({ children, authProvider, config }: Props): JSX.Element | null {
  const { LAUNCH_DARKLY } = config;
  const { userDetails: user } = useProvider(authProvider);

  const { data: LDAsyncProvider } = useQuery(
    ['LaunchDarkly'],
    () => {
      return asyncWithLDProvider({
        clientSideID: LAUNCH_DARKLY.PROJECTS.ADMIN_DASH,
        options: { bootstrap: 'localStorage' },
        reactOptions: { useCamelCaseFlagKeys: false },
        context: user
          ? {
              email: user.email,
              firstName: user.firstName,
              lastName: user.lastName,
              key: user.uuid,
              anonymous: false,
            }
          : { anonymous: true },
      });
    },
    {
      suspense: true,
      refetchInterval: 0,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
    },
  );
  if (!LDAsyncProvider) {
    return null;
  }
  return <LDAsyncProvider>{children} </LDAsyncProvider>;
}

/**
 * Load the value of a single feature flag, providing a fallback value. The key should match the
 * name of the flag in LaunchDarkly exactly if `useCamelCaseFlagKeys` is set to false, otherwise it
 * should be the camelcase version of the key.
 */
export function useFeatureFlag<T>(key: string, fallbackValue: T, accountUuid?: string): T {
  const client = useLDClient();

  if (!client) {
    return false as T;
  }

  const flagValue = client.variation(key, fallbackValue);

  // If flag contains accountUuids, evaluate feature flag by accountUuid
  if (flagValue.accountUuids) {
    if (!accountUuid) {
      return fallbackValue;
    }
    return flagValue.accountUuids.includes(accountUuid) ? (true as T) : fallbackValue;
  }

  // Else simply return the flagValue
  return flagValue;
}
