import { PolicyBillingType } from '@newfront-insurance/coverage-api';

export const POLICY_BILLING_TYPE_LABEL_MAP: Record<PolicyBillingType, string | undefined> = {
  [PolicyBillingType.AGENT]: 'Agency - Pay in full',
  [PolicyBillingType.FINANCED]: 'Agency - Financed',
  [PolicyBillingType.INSTALLMENTS]: 'Agency - Installments',
  [PolicyBillingType.AGENCY_MULTI_ENTITY]: 'Agency - Multi-entity',
  [PolicyBillingType.AGENCY_MONTHLY_REPORTING]: 'Agency - Monthly reporting',
  [PolicyBillingType.DIRECT]: 'Direct - Pay in full',
  [PolicyBillingType.DIRECT_FINANCED]: 'Direct - Financed',
  [PolicyBillingType.DIRECT_INSTALLMENTS]: 'Direct - Installments',
  [PolicyBillingType.DIRECT_MULTI_ENTITY]: undefined,
  [PolicyBillingType.DIRECT_MONTHLY_REPORTING]: 'Direct - Monthly reporting',
};
