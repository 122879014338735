import { TooltipProvider } from '@newfront-insurance/core-ui/v2';
import type { TRPCClientHooks } from '@newfront-insurance/data-layer-client';
import { NextApiErrorBoundary } from '@newfront-insurance/data-layer-client';
import {
  SSRBoundary,
  type AuthProviderContext,
  type AuthProviderProps,
  LoginBoundary,
} from '@newfront-insurance/next-auth';
import { RouterProvider } from '@newfront-insurance/next-router-provider';
import type { Provider } from '@newfront-insurance/react-provision';
import type { ConfigType } from '@newfront-insurance/shared-public-config';
import type { QueryObserverOptions } from '@tanstack/react-query';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import type { AnyRouter } from '@trpc/server';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { Suspense, type ReactNode } from 'react';

import { AnalyticsProvider } from './providers/analytics';
import { AuthNextSwrProvider } from './providers/auth-swr';
import { ConditionalAuthProvider } from './providers/conditional-auth-provider';
import { DatadogWrapper } from './providers/datadog-wrapper';
import { FeatureFlagProvider } from './providers/feature-flags';

export interface DdRumConfig {
  applicationId: string;
  clientToken: string;
  appName: string;
}

interface AppProvidersProps {
  children: ReactNode;
  config: ConfigType;
  authProvider: Provider<AuthProviderContext, AuthProviderProps>;
  trpcProvider: Provider<TRPCClientHooks<AnyRouter>>;
  ddRumConfig: DdRumConfig;
  useConditionalAuthProvider: boolean;
  analyticsAppName: string;
}

const NotificationProvider = dynamic(
  async () => {
    const module = await import('@newfront-insurance/next-notifications');
    return module.NotificationProvider;
  },
  {
    ssr: false,
  },
);

function ReactNotificationProvider({ children }: { children: ReactNode }): JSX.Element {
  return <NotificationProvider>{children}</NotificationProvider>;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      useErrorBoundary: (_error, query) => {
        return !query.state.data && (query.options as QueryObserverOptions).suspense === true;
      },
    },
  },
});

/**
 * Used to apply a custom name and service to a view
 */
export function GeneralAppProviders({
  children,
  config,
  ddRumConfig,
  authProvider: AuthProvider,
  trpcProvider: TRPCProvider,
  useConditionalAuthProvider,
  analyticsAppName,
}: AppProvidersProps): JSX.Element {
  return (
    <NextApiErrorBoundary>
      <Head>
        <link rel="preconnect" href={config.API.AUTH} />
      </Head>
      <SSRBoundary>
        <RouterProvider>
          <ConditionalAuthProvider useConditionalAuthProvider={useConditionalAuthProvider} authProvider={AuthProvider}>
            <LoginBoundary authProvider={AuthProvider}>
              <Suspense fallback={null}>
                <QueryClientProvider client={queryClient}>
                  <FeatureFlagProvider config={config} authProvider={AuthProvider}>
                    <DatadogWrapper config={ddRumConfig} authProvider={AuthProvider}>
                      <AuthNextSwrProvider authProvider={AuthProvider}>
                        <ReactNotificationProvider>
                          <AnalyticsProvider
                            authProvider={AuthProvider}
                            config={config}
                            analyticsAppName={analyticsAppName}
                          >
                            <TRPCProvider>
                              <TooltipProvider>{children}</TooltipProvider>
                            </TRPCProvider>
                          </AnalyticsProvider>
                        </ReactNotificationProvider>
                      </AuthNextSwrProvider>
                    </DatadogWrapper>
                  </FeatureFlagProvider>
                </QueryClientProvider>
              </Suspense>
            </LoginBoundary>
          </ConditionalAuthProvider>
        </RouterProvider>
      </SSRBoundary>
    </NextApiErrorBoundary>
  );
}
