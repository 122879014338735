import type { Alpha3Code } from 'i18n-iso-countries';
import type { internationalStreet } from 'smartystreets-javascript-sdk';

import type { Address, CreateAddressFormValues, Suggestion } from '../types';

export function transformSmartyStreetsLookupToAddress(suggestion: Suggestion): Address {
  const { streetLine, secondary, city, state, zipcode, entries } = suggestion;

  const address: Address = {
    addressLine1: streetLine,
    addressLine2: secondary || '',
    addressLine3: '',
    city,
    countryCode: 'USA',
    formattedAddress: '',
    postalCode: zipcode,
    stateOrArea: state,
    entries,
  };

  address.formattedAddress = formatStreetAddress(address);

  return address;
}

export function transformFormValuesToAddress(formValues: CreateAddressFormValues): Address {
  const { displayName, addressLine1, addressLine2, city, stateOrArea, postalCode, countryCode } = formValues;

  const address = {
    displayName,
    addressLine1,
    addressLine2,
    city,
    formattedAddress: '',
    countryCode: countryCode as Alpha3Code,
    stateOrArea,
    postalCode,
  };

  address.formattedAddress = formatStreetAddress(address);

  return address;
}

export function formatStreetAddressLines(address: Address): string {
  const { addressLine1, addressLine2, addressLine3 } = address;
  let streetAddress = addressLine1;

  if (addressLine2) {
    streetAddress = `${streetAddress}, ${addressLine2}`;
  }

  if (addressLine3) {
    streetAddress = `${streetAddress}, ${addressLine3}`;
  }

  return streetAddress;
}

export function formatCandidateToAddress(candidate: internationalStreet.Candidate): Address {
  const { administrativeArea, dependentLocality, locality, postalCode, countryIso3 } = candidate.components;

  const newAddress = {
    addressLine1: candidate.address1,
    city: dependentLocality || locality,
    formattedAddress: '',
    countryCode: countryIso3 as Alpha3Code,
    stateOrArea: administrativeArea,
    postalCode,
  } as Address;

  newAddress.formattedAddress = formatStreetAddress(newAddress);

  return newAddress;
}

export function formatStreetAddress(address: Address): string {
  const { city, stateOrArea, countryCode, postalCode } = address;
  const streetAddress = formatStreetAddressLines(address);
  return `${streetAddress}, ${city}, ${stateOrArea}, ${postalCode}, ${countryCode}`;
}
